/* body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */
//@import (css) '~react-dates/lib/css/_datepicker.css';
@import (css) '~react-toastify/dist/ReactToastify.css';
@import './semantic-ui/semantic.less';
@import './semantic-ui/CERN/globals/colors.less';
@import './semantic-ui/animations.less';
@import './semantic-ui/CERN/collections/table.variables';

.Resizer {
  background: #000;
  opacity: 0.2;
  z-index: 1;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
}

.Resizer:hover {
  -webkit-transition: all 2s ease;
  transition: all 2s ease;
}

.Resizer.horizontal {
  height: 11px;
  margin: -5px 0;
  border-top: 5px solid rgba(255, 255, 255, 0);
  border-bottom: 5px solid rgba(255, 255, 255, 0);
  cursor: row-resize;
  width: 100%;
}

.Resizer.horizontal:hover {
  border-top: 5px solid rgba(0, 0, 0, 0.5);
  border-bottom: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.vertical {
  width: 11px;
  margin: 0 -5px;
  border-left: 5px solid rgba(255, 255, 255, 0);
  border-right: 5px solid rgba(255, 255, 255, 0);
  cursor: col-resize;
}

.Resizer.vertical:hover {
  border-left: 5px solid rgba(0, 0, 0, 0.5);
  border-right: 5px solid rgba(0, 0, 0, 0.5);
}

.Resizer.disabled {
  cursor: not-allowed;
}

.Resizer.disabled:hover {
  border-color: transparent;
}

.SingleDatePicker_picker {
  z-index: 1900;
}

body {
  background-color: @mainBgColor;
}

@navBarHeight: 50px;
@navSidebarWidth: 150px;
@navSidebarWidthIconOnly: 50px;

#app-main-sidebar {
  top: @navBarHeight;
  height: calc(100% - @navBarHeight) !important;
  box-shadow: none;
  //width: @navSidebarWidth;
  //> .menu.item {
  //  position: absolute;
  //  bottom: @navBarHeight;
  //}
  transition: width 1s;

  .active.item {
    background: @mainBgColor;
    color: @mainColor !important;
  }
}

#app-main-navbar {
  height: @navBarHeight;
  box-shadow: none;
}

#app-main-container {
  margin-top: @navBarHeight+2;
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.Toastify {
  .Toastify__toast {
    border-radius: 5px;
  }

  .Toastify__toast--info {
    background: @blue-base;
  }

  .Toastify__toast--warning {
    background: @orange-base;
  }

  .Toastify__toast--error {
    background: @red-base;
  }

  .Toastify__toast--default {
    background: @grey-darken-3;
  }

  .Toastify__toast--success {
    background: @green-base;
  }

  .Toastify__toast-body {
    .toaster-title {
      font-size: 1.2em;
    }

    .toaster-message {
      font-size: 1em;
    }
  }
}

.ui.dimmer.modals {
  z-index: 1000 !important;
}

.ui.dimmer {
  z-index: 998 !important;
}



/**
 * Jsondiffpatch
 */

.jsondiffpatch-unchanged,
.jsondiffpatch-movedestination {
  display: none !important;
}

.jsondiffpatch-delta {
  font-family: 'Bitstream Vera Sans Mono', 'DejaVu Sans Mono', Monaco, Courier,
  monospace;
  font-size: 12px;
  margin: 0;
  padding: 4px 4px 4px 0;
  display: inline-block;
}
.jsondiffpatch-delta pre {
  font-family: 'Bitstream Vera Sans Mono', 'DejaVu Sans Mono', Monaco, Courier,
  monospace;
  font-size: 12px;
  margin: 0;
  padding: 0;
  display: inline-block;
}
ul.jsondiffpatch-delta {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.jsondiffpatch-delta ul {
  list-style-type: none;
  padding: 0 0 0 10px;
  margin: 0;
}
.jsondiffpatch-added .jsondiffpatch-property-name,
.jsondiffpatch-added .jsondiffpatch-value pre,
.jsondiffpatch-modified .jsondiffpatch-right-value pre,
.jsondiffpatch-textdiff-added {
  background: #bbffbb;
}
.jsondiffpatch-deleted .jsondiffpatch-property-name,
.jsondiffpatch-deleted pre,
.jsondiffpatch-modified .jsondiffpatch-left-value pre,
.jsondiffpatch-textdiff-deleted {
  background: #ffbbbb;
  text-decoration: line-through;
}
.jsondiffpatch-unchanged-showing .jsondiffpatch-unchanged,
.jsondiffpatch-unchanged-showing
.jsondiffpatch-movedestination
> .jsondiffpatch-value {
  max-height: 100px;
}
.jsondiffpatch-unchanged-hidden .jsondiffpatch-unchanged,
.jsondiffpatch-unchanged-hidden
.jsondiffpatch-movedestination
> .jsondiffpatch-value {
  max-height: 0;
}
.jsondiffpatch-unchanged-hiding
.jsondiffpatch-movedestination
> .jsondiffpatch-value,
.jsondiffpatch-unchanged-hidden
.jsondiffpatch-movedestination
> .jsondiffpatch-value {
  display: block;
}
.jsondiffpatch-unchanged-visible .jsondiffpatch-unchanged,
.jsondiffpatch-unchanged-visible
.jsondiffpatch-movedestination
> .jsondiffpatch-value {
  max-height: 100px;
}
.jsondiffpatch-unchanged-hiding .jsondiffpatch-unchanged,
.jsondiffpatch-unchanged-hiding
.jsondiffpatch-movedestination
> .jsondiffpatch-value {
  max-height: 0;
}
.jsondiffpatch-unchanged-showing .jsondiffpatch-arrow,
.jsondiffpatch-unchanged-hiding .jsondiffpatch-arrow {
  display: none;
}
.jsondiffpatch-value {
  display: inline-block;
}
.jsondiffpatch-property-name {
  display: inline-block;
  padding-right: 5px;
  vertical-align: top;
}
.jsondiffpatch-property-name:after {
  content: ': ';
}
.jsondiffpatch-child-node-type-array > .jsondiffpatch-property-name:after {
  content: ': [';
}
.jsondiffpatch-child-node-type-array:after {
  content: '],';
}
div.jsondiffpatch-child-node-type-array:before {
  content: '[';
}
div.jsondiffpatch-child-node-type-array:after {
  content: ']';
}
.jsondiffpatch-child-node-type-object > .jsondiffpatch-property-name:after {
  content: ': {';
}
.jsondiffpatch-child-node-type-object:after {
  content: '},';
}
div.jsondiffpatch-child-node-type-object:before {
  content: '{';
}
div.jsondiffpatch-child-node-type-object:after {
  content: '}';
}
.jsondiffpatch-value pre:after {
  content: ',';
}
li:last-child > .jsondiffpatch-value pre:after,
.jsondiffpatch-modified > .jsondiffpatch-left-value pre:after {
  content: '';
}
.jsondiffpatch-modified .jsondiffpatch-value {
  display: inline-block;
}
.jsondiffpatch-modified .jsondiffpatch-right-value {
  margin-left: 5px;
}
.jsondiffpatch-moved .jsondiffpatch-value {
  display: none;
}
.jsondiffpatch-moved .jsondiffpatch-moved-destination {
  display: inline-block;
  background: #ffffbb;
  color: #888;
}
.jsondiffpatch-moved .jsondiffpatch-moved-destination:before {
  content: ' => ';
}
ul.jsondiffpatch-textdiff {
  padding: 0;
}
.jsondiffpatch-textdiff-location {
  color: #bbb;
  display: inline-block;
  min-width: 60px;
}
.jsondiffpatch-textdiff-line {
  display: inline-block;
}
.jsondiffpatch-textdiff-line-number:after {
  content: ',';
}
.jsondiffpatch-error {
  background: red;
  color: white;
  font-weight: bold;
}


// Param Change Tooltip
.param-change-tooltip {
  background-color: #FFF;
  border: 1px solid #333;
  padding: 4px;
  max-height: 100px;
  overflow-y: scroll;
}

.param-change-tooltip-right-half {
  transform: translate(calc(-100% + 5px), 0);
}