.fx-pulse {
  animation: pulse 1s infinite;
  animation-direction: alternate;
  animation-name: pulse;
}
@pulseScaleDiff: .1;

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1 + @pulseScaleDiff);
  }
  100% {
    transform: scale(1);
  }
}
