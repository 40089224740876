

@navBarHeight: 50px;

#app-main-sidebar {
  top: @navBarHeight;
  box-shadow: none;
  //> .menu.item {
  //  position: absolute;
  //  bottom: @navBarHeight;
  //}
}

#app-main-navbar {
  height: @navBarHeight;
  box-shadow: none;
}

#app-main-search{
  width: 300px;
  margin: auto;
}

#cern-toolbar {
  background-color: #222;
  font-family: 'PT Sans', 'Trebuchet MS', Tahoma, sans-serif;
  color: #999;
  font-size: 14px;
  line-height: 1;
  padding: 0 5%;
  height: 40px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  box-sizing: border-box;

  &.signin-expand {
    z-index: 2000;
  }

  a {
    color: #ccc;
    text-decoration: none;
    padding: 6px 8px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    display: inline-block;

    :hover {
      background-color: #000;
      color: #fff;
      border: 0;
    }
  }

  .active {
    a {
      background-color: #f6f6f6;
      color: #000;
      padding-bottom: 20px;
    }

    .cern-account {
      background-color: #fff;
    }
  }

  h1 {
    float: left;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    position: relative;
    left: -8px;
    padding: 6px 0;
    letter-spacing: 0.08em;
    margin: 0;

    a {
      color: #fff;
    }

    span {
      color: #999;
      letter-spacing: normal;
      padding: 0;
    }

    a:hover span {
      color: #fff;
    }
  }

  ul {
    float: right;
    list-style: none;
    position: relative;
    right: -8px;
    margin: 0;

    &.cern-signedin.toolbar-submenu {
      padding-left: 0;
    }

    li {
      float: left;
      display: block;
      margin-left: 0.6em;
      padding: 6px 0;

      a {
        border-bottom-width: 0;
      }

      &.signin {
        position: relative;

        a.cern-signin {
          color: #ccc;
          display: inline-block;
          cursor: pointer;
        }

        div.item-list {
          display: none;
          position: absolute;
          background: rgba(30, 30, 30, 0.95);
          z-index: 99999;
          top: 40px;
          min-width: 200px;


          div.item-list {
            position: static;
          }
        }

        &.signin-expand div.item-list {
          display: block;
        }
      }
    }
  }

  .cern-multifactor {
    padding-left: 32px;
    background-image: url(https://webtools.web.cern.ch/sites/webtools.web.cern.ch/modules/custom/cern_toolbar/assets/img/toolbarsprite.png);
    background-repeat: no-repeat;
    background-position: 0 -120px;
  }

  .cern-signout {
    margin-left: 1em;
  }


  .cern-account-links {
    width: 100%;
  }

  div.item-list-length-1, div.item-list-length-1 div.item-list {
    display: inline !important;
    position: static !important;
    top: 0 !important;
    min-width: auto !important;

    ul.toolbar-submenu {
      padding: 0 !important;

      li.cern-account-links {
        padding: 0;
        margin: 0;
      }
    }
  }

  @media only screen and (min-width: 751px) {
    ul li.signin.signin-expand {
      padding: 6px 0 0 0;

      > a {
        color: #333 !important;
        background: #f6f6f6;
      }
    }

    & > ul > li.signin.signin-expand > a {
      padding: 6px 8px 14px 8px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }


    &:not(.user-authenticated) ul li.signin div.item-list ul {
      padding: 5px;
    }

    &.user-authenticated ul li.signin {
      padding-top: 0;

      div.item-list {
        display: block;
        position: static;
      }

      a.cern-signin.sign-out {
        display: none;
      }
    }

    span.cern-signin-label {
      padding-top: 6px;
      display: inline-block;
    }

    div.item-list-length-1, div.item-list-length-1 div.item-list {
      display: inline !important;
      position: static !important;
      top: 0 !important;
      min-width: auto !important;

      ul.toolbar-submenu {
        padding: 0 !important;
      }

      li.cern-account-links {
        padding: 0;
        margin: 0;
      }
    }
  }

  @media only screen and (max-width: 750px) {
    ul li {
      a {
        height: 38px;
        background-position-x: 0;
      }

      &.signin div.item-list {
        right: -70px;


        ul {
          position: static;
          float: none;
          border: 0;
          padding-left: 15px;
        }
      }
    }

    &.user-authenticated ul li {
      a.cern-signin.sign-out {
        background-position-y: -80px;
      }


      &.signin div.item-list ul {
        padding-left: 5px;
        padding-top: 5px;

        li {
          float: none;
          border: 0;


          a {
            text-indent: 0;
            background: transparent;
            width: 100%;
            border: 0;
          }
        }
      }
    }
  }

  a, a span {
    transition: background-color 0.1s ease-out;
  }

  @media only screen and (max-width: 750px) {
    h1 span {
      display: none;
    }

    ul li {
      padding: 0;
      margin: 0;

      &:last-child a {

        border-right: 1px solid #000;
        box-shadow: 1px 0 0 #444;
      }

      a.cern-single-mobile-signin:not(.cern-multiple-mobile-signin), li a:not(.cern-multiple-mobile-signin) {
        background-image: url(https://webtools.web.cern.ch/sites/webtools.web.cern.ch/modules/custom/cern_toolbar/assets/img/toolbarsprite.png) !important;
        background-repeat: no-repeat !important;
        height: 40px !important;
        width: 40px !important;
        border-radius: 0 !important;
        text-indent: -5000px !important;
        overflow: hidden !important;
        border-left: 1px solid #444 !important;
        box-sizing: border-box !important;
      }

      .cern-account {
        background-position: 9px 0;
      }

      .cern-directory {
        background-position: 0px -40px;
      }

      .cern-signout {
        background-position: 9px -80px;
        margin-left: 0;
      }

      .active {
        .cern-account {

          background-position: -31px 0;
        }

        .cern-directory {
          background-position: -31px -40px;
        }
      }

      .cern-accountlinks span {
        display: none;
      }

      .cern-multifactor {
        background-image: none;
        padding: 0;
      }
    }
  }


  a.account em {
    font-style: normal;
  }
}
