@import "ag-grid-community/styles/ag-grid.css";
@import "ag-grid-community/styles/ag-theme-quartz.css";

html,
body {
  margin: 2px !important;
  height: unset !important;
}

#app-main-navbar {
  height: 50px !important;
  z-index: 999 !important;
}

#app-main-sidebar.state-open ~ #app-main-container {
  padding-left: 150px;
}

#app-main-sidebar.state-icon-only ~ #app-main-container {
  padding-left: 50px;
}

#map {
  height: 500px;
}

.navbar {
  position: fixed !important;
  top: 0;
  left: 0;
  right: 0;
  height: 50px !important;
  z-index: 5 !important;
}

#app-main-sidebar {
  top: 50px; /*@navBarHeight;*/
  box-shadow: none;
  z-index: 999 !important;
}

#app-main-container {
  margin-top: 54px; /*@navBarHeight + 4;*/
  width: 100%;
}

#app-main-sidebar.state-icon-only ~ #app-main-container {
  padding-left: 50px !important;
}

.Toastify__toast-container--top-right {
  margin-top: 54px !important;
}

.grayscale {
  filter: grayscale(100%);
}

.hazardOpacity {
  opacity: 0.2;
}

/* Modals */

.modal.containerReducedWhiteSpace .header,
.modal.containerReducedWhiteSpace .content {
  padding: 10px !important;
}

/* Container, Accordion or Grid */
.containerReducedWhiteSpace .ui.grid > .column,
.containerReducedWhiteSpace > .column {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.containerReducedWhiteSpace .ui.grid > .row,
.containerReducedWhiteSpace > .row {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.containerReducedWhiteSpace .message {
  padding-top: 0.5em !important;
  padding-bottom: 0.5em !important;
}

.containerReducedWhiteSpace .content {
  padding: 0.5em 0.5em 1.5em 0.5em !important;
}

.containerReducedWhiteSpace.containerContentReducedWhiteSpace > .content {
  padding-top: 0em !important;
  padding-bottom: 0.5em !important;
}

.containerReducedWhiteSpace .title {
  padding: 0.5em 0.5em !important;
}

.containerReducedWhiteSpace .title {
  padding: 0.5em 0.5em !important;
}

.title .ui.loader {
  position: absolute !important;
  right: 0px !important;
  top: unset !important;
  left: unset !important;
  padding: 1.4em 1em !important;
}

.containerReducedWhiteSpace .column {
  padding-right: 6px !important;
  padding-left: 6px !important;
}

.containerReducedWhiteSpace .content > table {
  padding: 0px !important;
}

.ui.accordion .accordion {
  margin-top: 0.3em !important;
}

/* Columns */
.column.detailsGridCol {
  padding: 0em 0.3em 0em 0.3em !important;
}

.detailsGridCol h3,
.detailsGridCol h2 {
  margin: 0px !important;
}

.column.cpGeneralDetGridCol {
  padding-right: 0.3em !important;
}

.column.cpChemEnvDetGridCol {
  padding-left: 0.3em !important;
}

.column.cpGeneralDetGridColI {
  padding-bottom: 0em !important;
}

.column.cpChemEnvDetGridColI {
  padding-top: 0em !important;
}

.buildingSummaryGrid .riskValue {
  padding: 6px 3px 6px 3px;
}

/* Fields */
.hiddenField {
  display: none !important;
}

.noMargin {
  margin: 0em !important;
}

.ui.disabled.input,
.ui.input:not(.disabled) input[disabled] {
  opacity: unset !important;
}

.field label {
  margin-bottom: 0px !important;
  font-weight: bold !important;
}

.ui.labeled.input > .label:not(.corner) {
  padding-top: 0.5em !important;
}

.error.field p {
  margin: 0px;
}

.hazardCell {
  cursor: pointer;
}

.disabled .hazardCell {
  opacity: 0.65;
}

.DateInput__disabled {
  background: white !important;
}

.DateInput_input {
  max-height: 1.89em !important;
}

.filtering-input-wrapper {
  max-height: 1.89em;
}

.inputDefaultButton {
  padding-left: 5px !important;
  padding-right: 5px !important;
  float: left;
}

.inputWithTwoInputDefaultButtons {
  width: calc(100% - 46px);
  float: left;
}

.inputWithInputDefaultButton {
  width: calc(100% - 26px);
  float: left;
}

.left-floated {
  float: left;
}

.checkboxMargin label {
  margin: 7px 21px 7px auto !important;
}

.ppeIcon img {
  margin-right: 4px;
}

/* Risk calculations */
.riskValue {
  float: right;
  padding: 0px 3px 0px 3px;
  border-radius: 0.28571429rem;
  margin-left: 5px;
}

.enableRiskCalculation {
  padding-left: 10px;
}

/* Person list table */
.changesNotificationRecipients .personListTable {
  margin-top: 1rem !important;
}

.personListTable {
  margin-top: 0.3em !important;
}

.personListTable th {
  padding: 0.3em 0em 0.3em 0.3em !important;
  font-size: 0.9em !important;
}

.personListTable td {
  padding: 0em 0em 0em 0.3em !important;
  font-size: 0.9em !important;
}

.personListTable .mini {
  padding: 5px !important;
  float: right;
}

.detailsFormOptions {
  padding: 5px 0px 5px 0px !important;
}

/* File wrapper input */
.fileWrapperUploader {
  border-style: dashed;
  border-width: 1px;
}

.fileWrapperUploader input {
  cursor: pointer !important;
}

.fileWrapperUploader label {
  position: absolute;
  padding: 4px;
  left: 50%;
}

.fileWrapperUploader label i {
  position: relative;
  left: -50%;
}

.fileWrapperUploader input {
  opacity: 0 !important;
}

.dangerCodeContainer {
  border: none !important;
  text-align: center;
  border-collapse: collapse !important;
}

.dangerCode {
  border: 1px solid black !important;
  background-color: #ff8000 !important;
}

.accordionHeaderCompact {
  padding-bottom: 2px !important;
}

.accordionContentCompact {
  /*padding: 0 5px 0 5px !important;
  margin: 0 5px 0 5px !important;*/
}

.accordionNoBorder {
  border: none !important;
  box-shadow: 0 0 0 0 rgba(1, 1, 1, 0), 0 0 0 0 rgba(1, 1, 1, 0) !important;
  background-color: rgba(0, 0, 0, 0) !important;
}

.accordionNoBorder > .title {
  padding: 2px !important;
}

.accordionNoBorder > .content {
  padding: 5px 0 0 0 !important;
}

.filterPanel {
  margin-top: -5px !important;
  background-color: rgba(0, 0, 0, 0.02) !important;
}

.filterPanel > .title {
  padding: 10px 0 10px 5px !important;
}

.filterPanel > .content {
  padding: 5px 5px 0 5px !important;
}

.marginRightButton {
  margin-right: 0 !important;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome, Opera and Firefox */
}

.inputWidthStretched {
  width: 100%;
}

.inputWhiteBackGround {
  background-color: white !important;
}

.tableCellReducedPadding {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.tableCellOption {
  padding: 2px !important;
}

.hasFilterValue {
  /*border-color: #66bfff;*/
  box-shadow: 0 0 0 3px rgba(0, 149, 255, 0.15);
  border-radius: 0.28571429rem;
  /*box-shadow: 0 0 5px rgb(206, 238, 200); !*
  box-shadow: 0 0 5px rgba(81, 203, 238, 1); *!
  border-radius: 0.28571429rem;
  border: 1px solid rgb(206, 238, 200);*/
}

.DateInput_input:not(:placeholder-shown) {
  max-height: 1.89em !important;
  box-shadow: 0 0 0 3px rgba(0, 149, 255, 0.15) !important;
  border-radius: 0.28571429rem !important;
}

.ui.container > .dimmer {
  position: fixed;
}

.batchUpdateFieldList .dimmer {
  z-index: 1 !important;
}

/* statistic tiles */
.statisticTiles {
  margin-top: 100px !important;
}

/* Tiles */
.simple-tile {
  opacity: 0.8;
  margin: 25px;
  width: 260px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #102241;
  color: white;
}

.simple-tile:hover {
  opacity: 1;
}

.simple-tile-title {
  padding: 10px !important;
  position: relative;
  z-index: 2;
  color: white !important;
  font-size: 22px !important;
  display: table-cell !important;
  vertical-align: middle !important;
  margin: 0 !important;
}

.simple-tile-subtitle {
  font-size: 80%;
}

.version-sw {
  font-size: 8pt;

  -moz-user-select: text;
  -khtml-user-select: text;
  -webkit-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.displayInlineBlock {
  display: inline-block !important;
}

.centered-button {
  display: block !important;
  margin: auto !important;
}

.limited-size-widget-editor {
  max-height: 50vh;
  overflow-y: auto;
}

.page-header {
  display: flex;
  justify-content: space-between;
}

.display-end {
  display: flex;
  justify-content: flex-end;
}

.chess-transparent-background, .chess-transparent-background-img img {
  background-image: linear-gradient(45deg, #eeeeee 25%, transparent 25%), linear-gradient(-45deg, #eeeeee 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #eeeeee 75%), linear-gradient(-45deg, transparent 75%, #eeeeee 75%);
  background-size: 20px 20px;
  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
}

.white-background-img img {
  background-color: #ffffff;
}

.blue-thin-border img {
  border: 1px solid blue;
}

.black-thin-border img {
  border: 1px solid black;
}

.no-padding {
  padding: 0 !important;
}

.mini-padding {
  padding: 2px !important;
}

.tiny-padding {
  padding: 5px !important;
}

.small-padding {
  padding: 8px !important;
}

.no-margin {
  margin: 0 !important;
}

.mini-margin {
  margin: 2px !important;
}

.tiny-margin {
  margin: 5px !important;
}

.small-margin {
  margin: 8px !important;
}

.truncate-text-small {
  overflow: hidden;        /* Ensures the overflow is not visible */
  white-space: nowrap;     /* Keeps the text on a single line */
  text-overflow: ellipsis; /* Adds ellipses at the end of the text if it overflows */
  max-width: 50px;            /* Set to the desired width, or use max-width */
}

.truncate-text-medium {
  overflow: hidden;        /* Ensures the overflow is not visible */
  white-space: nowrap;     /* Keeps the text on a single line */
  text-overflow: ellipsis; /* Adds ellipses at the end of the text if it overflows */
  max-width: 100px;            /* Set to the desired width, or use max-width */
}

.truncate-text-big {
  overflow: hidden;        /* Ensures the overflow is not visible */
  white-space: nowrap;     /* Keeps the text on a single line */
  text-overflow: ellipsis; /* Adds ellipses at the end of the text if it overflows */
  max-width: 200px;            /* Set to the desired width, or use max-width */
}

.chart-threshold-name {
  font-size: 12px;
}

.react-datepicker-popper {
  z-index: 2000;
}