@import "semantic-ui/CERN/globals/site.variables";

@media only screen and (max-width: @largestMobileScreen) {
  .ui.stackable.grid > .row > .wide.column {
    padding-left: 0.1rem !important;
    padding-right: 0.1rem !important;
  }

  .ui.segment {
    padding-left: 0.1rem !important;
    padding-right: 0.1rem !important;
  }

  .ui.container {
    margin-left: 0.1rem !important;
    margin-right: 0.1rem !important;
  }
}