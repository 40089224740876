/*******************************
         Site Overrides
*******************************/


@media only screen and (max-width : @largestMobileScreen) {
.ui.table:not(.unstackable) thead > tr, 
.ui.table:not(.unstackable) thead td {  
    background: @headerBackground;
    padding: 0 !important;
  }

}